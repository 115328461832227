import { Injectable } from '@angular/core';
import { interval, Observable, race, Subscription } from 'rxjs';
import { ReservationsService } from '@data/reservations/reservations.service';
import { filter, pluck, switchMap, tap } from 'rxjs/operators';
import { LaravelBoolean } from '@core/http/crud-model/laravel-boolean';
import { ReservationResource } from '@data/reservations/reservations.model';
import { ModalService } from '@shared/services/modal.service';
import { ReservationsNotTakenModalService } from '@pages-and-modals/reservations-not-taken-modal/reservations-not-taken-modal.service';
import { NavigationStart, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ROUTES } from '@core/routes';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkshopOfflineNotificationsInterval {
  callToApiIntervalSubscription = new Subscription();
  whenModalShouldBeOpenedSubscription = new Subscription();
  period: number = 10000;
  call: Observable<ReservationResource[]> = this.reservationsService
    .get({
      page: 1,
      paginate: (0 as unknown) as LaravelBoolean,
      per_page: 1,
      sort: 'created_at.desc',
      tab: 'not_taken',
      with: '',
    })
    .pipe(
      pluck('data'),
      tap(() => this.log('call to API'))
    );
  disabledRoutes = [ROUTES.REQUEST_ADD_EDIT.path];

  constructor(
    private reservationsService: ReservationsService,
    private modalService: ModalService,
    private reservationsNotTakenModalService: ReservationsNotTakenModalService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  register(): void {
    this.log('register');
    this.callToApiIntervalSubscription = this.callToApiInterval();
  }

  unregister(): void {
    this.log('unregister');
    this.callToApiIntervalSubscription.unsubscribe();
    this.whenModalShouldBeOpenedSubscription.unsubscribe();
  }

  private callToApiInterval() {
    return interval(this.period)
      .pipe(
        tap(() => this.log('interval')),
        switchMap(() => this.call)
      )
      .subscribe((reservations: ReservationResource[]) => {
        this.handleApiResponse(reservations);
      });
  }

  private handleApiResponse(reservations: ReservationResource[]) {
    if (reservations.length > 0) {
      this.log('unreaded reservations exists');
      this.log('stop interval');
      this.callToApiIntervalSubscription.unsubscribe();
      if (this.canOpenModal()) {
        this.log('can open modal');
        this.openModal();
      } else {
        this.log('listen should be opened');
        this.whenModalShouldBeOpenedSubscription = this.listenWhenModalShouldBeOpened();
      }
    } else {
      this.log('no unreaded reservations');
    }
  }

  private canOpenModal(): boolean {
    return this.isOpenableRoute(this.router.url) && this.modalService.noModalIsOpen();
  }

  private openModal(): void {
    // this.resetDates();
    // this.register(); // subscribe call to API

    this.resetDates()
      .pipe(
        switchMap(() => {
          this.log('open modal');
          return this.reservationsNotTakenModalService.openModal();
        })
      )
      .subscribe((result) => {});
  }

  private listenWhenModalShouldBeOpened(): Subscription {
    const trigger1 = this.router.events.pipe(
      filter((e) => e instanceof NavigationStart && !e.url.includes('request')),
      tap(() => {
        this.log('route change (different than disabled routes');
      })
    );
    const trigger2 = this.dialog.afterAllClosed.pipe(
      filter(Boolean),
      tap(() => {
        this.log('all dialogs are closed');
      })
    );
    // trigger1.subscribe(this.log);
    // trigger2.subscribe(this.log);
    return race(trigger1, trigger2).subscribe(() => {
      this.whenModalShouldBeOpenedSubscription.unsubscribe();
      this.openModal();
    });
  }

  private resetDates(): Observable<any> {
    // TODO: reset dates (call to API)
    return this.reservationsService.updateNotificationDateAll().pipe(
      tap(() => {
        this.log('reset dates');
      })
    );
  }

  private isOpenableRoute(url: string): boolean {
    return this.disabledRoutes.includes(url);
  }

  private log(message: string): void {
    if (environment.logIntervals) {
      console.log(`%c${this.constructor.name}`, 'background:#62cd7a;color:#fff;', message);
    }
  }
}
